import React from "react";
import {Add_Image_Barbers, Add_New_Barbers, BaseUrl, SiteUrl} from "../apis";
import Flickity from 'react-flickity-component'
import {LazyLoadImage} from 'react-lazy-load-image-component';
import Skeleton from "react-loading-skeleton"
import {Link} from 'react-router-dom'
import {Icon} from "semantic-ui-react";
import {Button, Form, Modal, Tooltip, Upload} from "antd";
import Toast_noty from "./toasty_notification";
import Localforage from "localforage";
import {UploadOutlined} from "@ant-design/icons";


export default function OWL_PAPER(props) {
    const [add_barber_modal, setAdd_barber_modal] = React.useState(false);
    const [button_loading, setButton_loading] = React.useState(false);
    const [image_upload_modal, setImage_upload_modal] = React.useState(false);
    const [selected_barber, setSelected_barber] = React.useState({});
    const [subdomain, setSubdomain] = React.useState("");

    const [add_Package_form] = Form.useForm();
    React.useEffect(async ()=>{
        const local_data = await Localforage.getItem('data', async function (err, value) {
            return value
        });
        setSubdomain(local_data.shop.subdomain)
    },[])



    const [imageList, setImageList] = React.useState([]);

    const onChangeImage = ({fileList: newFileList}) => {
        setImageList(newFileList);
    };
    const onPreviewImage = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const dummyRequestImage = ({file, onSuccess}) => {
        onSuccess("ok");
    };



    function close_modal() {
        setAdd_barber_modal(false);
        add_Package_form.resetFields();
    }

    function close_modal_IMG_Upload() {
        setImage_upload_modal(false);
        setImageList([]);
    }

    async function Add_Image_API() {
        if(imageList.length === 0){return Toast_noty("image is required  (:", 5000, "warning");}
        setButton_loading(true);
        let data = new FormData();
        data.append('image', imageList[0].originFileObj);
        const Add_New_Barbers_Rec = Add_Image_Barbers(data, selected_barber._id, true);
        Add_New_Barbers_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                props.get_load();
                Toast_noty("Image Updated", 5000, "success");
                close_modal_IMG_Upload();
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    async function Add_Barber_API(value) {
        const Add_New_Barbers_Rec = Add_New_Barbers(value, true);
        Add_New_Barbers_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                props.get_load();
                Toast_noty("new barber is created", 5000, "success");
                close_modal();
            } else {
                Toast_noty("an error accured", 5000, "error");
            }
        })
    }


    const Custom_Barber_Comp = (data) => {
        return (
            <div key={1} className={`Inner_items`}>
                {data.image ?
                    <Tooltip title={`click to Update Image`}>
                    <div  onClick={() => {
                        setSelected_barber(data)
                        setImage_upload_modal(true)
                    }}><LazyLoadImage
                    alt={data._id.slice(-5)}
                    effect="blur"
                    src={`${BaseUrl}/barber/${data.image}`}
                /></div>
                    </Tooltip>
                    :
                    <>
                        <Tooltip title={`click to add Image`}>
                            <div style={{margin:"auto"}} onClick={() => {
                                setSelected_barber(data)
                                setImage_upload_modal(true)
                            }}>
                                <Skeleton style={{
                                    borderRadius: "100px",
                                    width: "200px",
                                    opacity: "0.15",
                                    cursor:"pointer"
                                }} height={200}/>
                            </div>
                        </Tooltip>
                    </>
                }
                <h3>{data.fullName}</h3>
                    <a    target={"_blank"}
                          href={`${SiteUrl}/bs/${subdomain}/reserve?B_I=${data._id}&&name=${data.fullName}`}
                          className={`button_public button`}>Reserve one Chair</a>
            </div>
        )
    }
    const ol = [0, 0, 0, 0]

    const ItemSlider = () => {
        return (
            <div id={"Team_Reserve"} className={`container`}>
                <h2>Team Members</h2>
                {/* <p>Reserve one chair Now</p> */}
                {props.loading ?
                    <div className={`rower`}>
                        {ol.map(val =>
                            <>
                                <div key={val} className={`Inner_items`}>
                                    <Skeleton style={{
                                        borderRadius: "100px",
                                        width: "200px",
                                        opacity: "0.15",
                                    }} height={200}/>
                                    <Skeleton style={{
                                        width: "200px",
                                        opacity: "0.15",
                                    }} height={30}/>
                                    <Skeleton style={{
                                        borderRadius: "20px",
                                        width: "200px",
                                        opacity: "0.15",
                                    }} height={50}/>
                                </div>
                            </>
                        )}
                    </div>
                    :
                    <>
                        <div className={`row_scroller_container`}>
                            <div onClick={() => {
                                setAdd_barber_modal(true)
                            }} className={`Inner_items add_new_barber_paper`}>
                                <Icon name='add'/>
                                <h4>create new barber</h4>
                            </div>
                            {props.barber_list.map((val) =>
                                <>
                                    {Custom_Barber_Comp(val)}
                                </>
                            )}
                        </div>
                    </>
                }
            </div>
        )
    }


    return (
        <>
            <ItemSlider/>


            {/*    create barber*/}


            <Modal
                title="create new barber"
                style={{top: 20}}
                visible={add_barber_modal}
                footer={null}
                onCancel={() => close_modal()}
            >
                <Form
                    requiredMark={false}
                    form={add_Package_form}
                    name="register"
                    onFinish={(val) => Add_Barber_API(val)}
                    scrollToFirstError
                    style={{marginTop: "1rem", width: "100%", textAlign: "center"}}
                >
                    <label className={`label_input`} htmlFor="title">full name</label>
                    <Form.Item
                        name="fullName"
                        rules={[{required: true, message: "insert full name"}]}
                    >
                        <input className={`_input`} placeholder={"insert full name"}/>
                    </Form.Item>

                    <label className={`label_input`} htmlFor="Email">Email</label>
                    <Form.Item
                        name="email"
                        rules={[{required: true, message: "insert email", type: "email"}]}
                    >
                        <input className={`_input`} placeholder={"insert email"}/>
                    </Form.Item>

                    <label className={`label_input`} htmlFor="prepayment">password</label>
                    <Form.Item
                        name="password"
                        rules={[{required: true, message: "insert password"}]}
                        help={"insert an strong password"}
                    >
                        <input className={`_input`} placeholder={"insert password"}/>
                    </Form.Item>

                    <Button loading={button_loading} className={`overall_button sign_in_button`} htmlType="submit">
                        + create
                    </Button>
                </Form>
            </Modal>

            <Modal
                title="Image Upload"
                style={{top: 20}}
                visible={image_upload_modal}
                footer={null}
                onCancel={() => close_modal_IMG_Upload()}
            >
                <Upload listType="picture-card"
                        action=''
                        fileList={imageList}
                        onChange={onChangeImage}
                        onPreview={onPreviewImage}
                        accept=".png,.jpg,.jpeg,.webp"
                        customRequest={dummyRequestImage}>
                    {imageList.length < 1 && <div className={`column_`}><UploadOutlined/><small>add image</small></div>}
                </Upload>
                <div className={`column_`}>
                    {imageList.length > 0 &&
                    <Button onClick={()=>{
                        Add_Image_API()
                    }} loading={button_loading} className={`overall_button sign_in_button`}> submit upload
                    </Button>}
                </div>
            </Modal>
        </>
    )
}