import React from "react";
import {Button, Form, Input, Modal, Popconfirm, Tooltip, Upload, Space} from "antd";
import Toast_noty from "../components/toasty_notification";
import {Get_Barber_List_Time, Edit_Profile, Block_Times, Get_single_Barbers, BaseUrl, Add_Image_Barbers} from "../apis";
import moment from "moment";
import {forageToolGetData} from "../components/localForageGetData";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import {UploadOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";


export default function Profile() {
    const [skeleton_loading, setSkeleton_loading] = React.useState(false);
    const [button_loading, setButton_loading] = React.useState(false);
    const [modal_work_time, setModal_work_time] = React.useState(false);
    const [barberTime, setBarberTime] = React.useState(null);
    const [barberData, setBarberData] = React.useState(null);
    const [barberServices, setBarberServices] = React.useState([]);
    const [now_time, setNow_time] = React.useState({status: 10, list: [], final_time: {}});
    const [picked_time, setPicked_time] = React.useState({T11: null, T12: null, T21: null, T22: null});
    const [reserve_data, setReserve_data] = React.useState(null);
    const [name_, setName_] = React.useState("null");
    const [barber_name_edit] = Form.useForm();
    const [image_upload_modal, setImage_upload_modal] = React.useState(false);
    const [imageList, setImageList] = React.useState([]);


    const onChangeImage = ({fileList: newFileList}) => {
        setImageList(newFileList);
    };
    const onPreviewImage = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const dummyRequestImage = ({file, onSuccess}) => {
        onSuccess("ok");
    };


    async function Get_Barber_TimeList_API() {
        const id = await forageToolGetData("id")
        setReserve_data(prev => ({...prev, barberId: id}))
        const Get_Barbers_Rec = Get_Barber_List_Time(id);
        Get_Barbers_Rec.then((data) => {
            if (data.result) {
                setBarberTime(data.time);
                setNow_time((prevState) => ({
                    ...prevState,
                    list: data.time[0],
                }));
                return data.data;
            } else {
                Toast_noty("you not set the work time yet", 5000, "warning");
            }
        })
    }

    async function Get_Single_Barber_API() {
        const id = await forageToolGetData("id")
        const Get_Barbers_Rec = Get_single_Barbers(id, true);
        Get_Barbers_Rec.then((data) => {
            if (data.result) {
                barber_name_edit.setFieldsValue(data.data)
                if (data.data.listServices) {
                    setBarberServices(data.data.listServices)
                    formServices.setFieldsValue(data.data)
                } else {
                    Toast_noty("You have not services", 5000, "warning");
                }
                setBarberData(data.data)
            } else {
                Toast_noty("barber data not load", 5000, "warning");
            }
        })
    }


    React.useEffect(async () => {
        await Get_Barber_TimeList_API()
        await Get_Single_Barber_API()
        const name = await forageToolGetData("name")
        setName_(name)
    }, [])


    function close_modal() {
        setModal_work_time(false);
    }

    const [add_time_form] = Form.useForm();
    const [formServices] = Form.useForm();

    async function editProfile(value, type) {
        const id = await forageToolGetData('id')
        const postData = {}

        if (type === "time") {
            if (!picked_time.T11 || !picked_time.T12 || !picked_time.T21 || !picked_time.T22) {
                return Toast_noty("set all time required", 5000, "info");
            }
            postData["listTime"] = [
                {start: picked_time.T11, end: picked_time.T12},
                {start: picked_time.T21, end: picked_time.T22}
            ]
            for (let i of postData.listTime) {
                if (moment(i.end, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds') < moment(i.start, 'HH:mm:ss: A').diff(moment().startOf('day'), 'seconds')) {
                    return Toast_noty("the start time should be less than end time", 5000, "waning");
                }
            }
        }
        if (type === "name") {
            postData["fullName"] = value.name
        }
        if (type === "status") {
            postData["isActive"] = value.isActive
        }
        if (type === "status") {
            postData["isActive"] = value
        }
        if (type === "services") {
            postData["listServices"] = value.listServices
        }
        setButton_loading(true)
        const Add_Time_Rec = Edit_Profile(id, postData, true);
        Add_Time_Rec.then((data) => {
            setButton_loading(false)
            if (data.result) {
                close_modal()
                Get_Barber_TimeList_API();
                Get_Single_Barber_API();
                Toast_noty("the information is updated", 5000, "success");
            } else {
                Toast_noty("an error accrued", 5000, "error");
            }
        })
    }

    function time_pick(val, type) {
        type === 11 && setPicked_time(prev => ({...prev, T11: val.target.value}))
        type === 12 && setPicked_time(prev => ({...prev, T12: val.target.value}))
        type === 21 && setPicked_time(prev => ({...prev, T21: val.target.value}))
        type === 22 && setPicked_time(prev => ({...prev, T22: val.target.value}))
    }


    async function block_time_Func() {
        const id = await forageToolGetData("id")
        if (!reserve_data.reserveDate) {
            return Toast_noty("choose a date", 5000, "warning")
        }
        if (!reserve_data.reserveTime) {
            return Toast_noty("choose a time", 5000, "warning")
        }
        setSkeleton_loading(true)
        const body = JSON.stringify({
            "reserveUnixTime": reserve_data.reserveUnixTime,
            "typeService": JSON.stringify(["de_active by barber"]),
            "fullname": name_,
            "userMobile": `09130001313`,
            "price": "10",
            "reserveTime": reserve_data.reserveTime,
            "reserveDate": reserve_data.reserveDate,
        })
        const New_Reserve_Rec = Block_Times(body, id, true);
        New_Reserve_Rec.then((data) => {
            if (data.result) {
                setNow_time(prev => ({...prev, status: 0}))
                setSkeleton_loading(false);
                Get_Barber_TimeList_API()
                Toast_noty("the time de actived successfully", 10000, "success");
            } else {
                Toast_noty(data.mes, 10000, "error");
            }
        })
    }


    function close_modal_IMG_Upload() {
        setImage_upload_modal(false);
        setImageList([]);
    }

    async function Add_Image_API() {
        const id = await forageToolGetData('id')
        if (imageList.length === 0) {
            return Toast_noty("image is required  (:", 5000, "warning");
        }
        setButton_loading(true);
        let data = new FormData();
        data.append('image', imageList[0].originFileObj);
        const Add_New_Barbers_Rec = Add_Image_Barbers(data, id, true);
        Add_New_Barbers_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Single_Barber_API()
                Toast_noty("Image Updated", 5000, "success");
                close_modal_IMG_Upload();
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    return (
        <>
            <div className={"shop_profile"}>
                <h1>Manege Name and Your active status</h1>
                <div className={"edit_profile_container"}>
                    <div className={"edit_profile_container_image"}>
                        {barberData?.image ?
                            <Tooltip defaultVisible={true} title={`click to Update Image`}>
                                <div onClick={() => {
                                    setImage_upload_modal(true)
                                }}><LazyLoadImage
                                    alt={barberData._id.slice(-5)}
                                    effect="blur"
                                    src={`${BaseUrl}/barber/${barberData.image}`}
                                /></div>
                            </Tooltip>
                            :
                            <>
                                <Tooltip defaultVisible={true} title={`click to add Image`}>
                                    <div style={{margin: "auto"}} onClick={() => {
                                        setImage_upload_modal(true)
                                    }}>
                                        <Skeleton style={{
                                            borderRadius: "100px",
                                            width: "200px",
                                            opacity: "0.15",
                                            cursor: "pointer"
                                        }} height={200}/>
                                    </div>
                                </Tooltip>
                            </>
                        }
                    </div>
                    <div className={"edit_profile_container_name_isActive"}>
                        <Form
                            requiredMark={false}
                            form={barber_name_edit}
                            name="add_logo"
                            onFinish={(val) => editProfile(val, 1)}
                            scrollToFirstError
                            style={{marginTop: "1rem", textAlign: "center"}}
                        >
                            <Form.Item
                                label={`Your Name`}
                                name="fullName"
                                rules={[{required: true, message: "insert shop name"}]}
                            >
                                <Input placeholder={"insert shop name"}/>
                            </Form.Item>
                            <Button loading={button_loading} className={`overall_button`} htmlType="submit">
                                Save Changes
                            </Button>
                        </Form>
                        <Tooltip defaultVisible={true} title={`Click to Change`}>
                            <div onClick={() => {
                                editProfile(!barberData.isActive, "status")
                            }} style={{justifyContent: `${barberData?.isActive ? "end" : "start"}`}}
                                 className={"edit_profile_container_isActive_box"}>
                           <span className={`edit_profile_container_isActive_box_relative_span`}
                                 style={{background: `${barberData?.isActive ? "#24A19C" : "#DD4A48"}`}}>
                            {barberData?.isActive ? "Active" : "De_Active"}
                           </span>
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>

            <h1>Manege Your Services</h1>
            <div className={"my_profile_services_container"}>
                <Form style={{maxWidth:"600px", margin:"auto"}} name="dynamic_form_nest_item" onFinish={(value)=>editProfile(value, "services")} autoComplete="off" form={formServices}>
                    <Form.List name="listServices">
                        {(fields, {add, remove}) => (
                            <>
                                {fields.map(({key, name, ...restField}) => (
                                    <Space key={key} style={{display: 'flex', marginBottom: 8}} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'service']}
                                            rules={[{required: true, message: 'service name'}]}
                                        >
                                            <Input placeholder="service name"/>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'price']}
                                            rules={[{required: true, message: 'service price'}]}
                                        >
                                            <Input placeholder="service price"/>
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)}/>
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        Add New Services
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button loading={button_loading}  className={`overall_button`}  type="primary" htmlType="submit">
                            Update Services
                        </Button>
                    </Form.Item>
                </Form>
            </div>


            <h1>Manage Working Time </h1>
            <div className={`work_timing_container`}>
                <Button onClick={() => {
                    setModal_work_time(true)
                }} className={`overall_button sign_in_button`}>setting new work timing</Button>
                <div className={'horiz_div_der'}/>
                <p style={{color: '#2d2c2c'}} className={`pay_notice`}>Notice! for de-active a time ,click on that</p>
                <p style={{color: '#2d2c2c'}} className={`pay_notice`}>Notice! for active again the de-avtived time,
                    search your name on my-reserve part and remove reserve </p>
                {barberTime ?
                    <>
                        <div className={`date_pick_container`}>
                            <div onClick={() => {
                                setNow_time((prev) => ({...prev, status: 0, list: barberTime[0]}))
                                setReserve_data(prev => ({...prev, reserveDate: barberTime[0][0].date}))

                            }}
                                 className={`date_pick_container_item ${now_time.status === 0 && 'services_items_active'}`}>
                                today
                                <div>
                                    {barberTime[0][0] ? barberTime[0][0].date : "work time ended"}
                                </div>
                            </div>
                            <div onClick={() => {
                                setNow_time((prev) => ({...prev, status: 1, list: barberTime[1]}))
                                setReserve_data(prev => ({...prev, reserveDate: barberTime[1][0].date}))
                            }}
                                 className={`date_pick_container_item ${now_time.status === 1 && 'services_items_active'}`}>
                                yesterday
                                <div>
                                    {barberTime[1][0].date}
                                </div>
                            </div>
                            <div onClick={() => {
                                setNow_time((prev) => ({...prev, status: 2, list: barberTime[2]}))
                                setReserve_data(prev => ({...prev, reserveDate: barberTime[2][0].date}))

                            }}
                                 className={`date_pick_container_item ${now_time.status === 2 && 'services_items_active'}`}>
                                two day ago
                                <div>
                                    {barberTime[2][0].date}
                                </div>
                            </div>
                        </div>
                        <div className={`date_pick_container date_pick_container_under`}>
                            {now_time.list.map((items) =>
                                <>
                                    <Popconfirm
                                        title={'do you de active this time?'}
                                        onConfirm={(val) => {
                                            block_time_Func()
                                        }} okText="yes" cancelText="no"
                                    >
                                        <div key={items.id} onClick={() => {
                                            if (!items.status) {
                                                setNow_time(prev => ({...prev, final_time: items}));
                                                setReserve_data(prev => ({...prev, reserveTime: items.time}))
                                                setReserve_data(prev => ({...prev, reserveUnixTime: items.id}))
                                            }
                                        }}
                                             className={`${!items.status ? `time_pick_container_item ${now_time.final_time.id === items.id && 'services_items_active'}` : 'time_pick_container_item_de_active'}`}>
                                            {items.time}
                                        </div>
                                    </Popconfirm>
                                </>
                            )}
                        </div>
                    </>
                    :
                    <h5 style={{color: "#f50", fontSize: "2.25rem", textAlign: "center"}}>you not set the work time
                        yet</h5>
                }

            </div>
            <Modal
                title="working time"
                style={{top: 20}}
                visible={modal_work_time}
                footer={null}
                onCancel={() => close_modal()}
            >
                <div className={'work_time_modal_container'}>
                    <h5>choose tow period time</h5>
                    <small>insert as format ( **:**)</small>
                    <br/>
                    <Form
                        className={"add_barber_modal"}
                        requiredMark={false}
                        form={add_time_form}
                        name="register"
                        onFinish={(value) => editProfile(value, "time")}
                        scrollToFirstError
                        style={{marginTop: "1rem", width: "100%", textAlign: "center"}}
                    >
                        <label className={`label_input`} htmlFor="email">first period<small> (morning) </small></label>
                        <Form.Item
                            className={'row'}
                            style={{marginBottom: "1rem"}}
                            name="first_time"
                        >
                            <div className={'row'}>
                                <input onChange={(val) => {
                                    time_pick(val, 11)
                                }} style={{maxWidth: 150}} className={`_input`} type={'time'}/>
                                <p>--- to --</p>
                                <input onChange={(val) => {
                                    time_pick(val, 12)
                                }} style={{maxWidth: 150}} className={`_input`} type={'time'}/>
                            </div>
                        </Form.Item>
                        <br/>
                        <label className={`label_input`} htmlFor="email">second period<small> (evening)</small></label>
                        <Form.Item
                            style={{marginBottom: "1rem"}}
                            name="second_time"
                        >
                            <div className={'row'}>
                                <input onChange={(val) => {
                                    time_pick(val, 21)
                                }} style={{maxWidth: 150}} className={`_input`} type={'time'}/>
                                <p>--- to --</p>
                                <input onChange={(val) => {
                                    time_pick(val, 22)
                                }} style={{maxWidth: 150}} className={`_input`} type={'time'}/>
                            </div>
                        </Form.Item>
                    </Form>

                    <Button onClick={(val) => editProfile(val, "time")} loading={button_loading}
                            className={`overall_button sign_in_button`} htmlType="submit">
                        set
                    </Button>
                </div>
            </Modal>

            <Modal
                title="Image Upload"
                style={{top: 20}}
                visible={image_upload_modal}
                footer={null}
                onCancel={() => close_modal_IMG_Upload()}
            >
                <Upload listType="picture-card"
                        action=''
                        fileList={imageList}
                        onChange={onChangeImage}
                        onPreview={onPreviewImage}
                        accept=".png,.jpg,.jpeg,.webp"
                        customRequest={dummyRequestImage}>
                    {imageList.length < 1 && <div className={`column_`}><UploadOutlined/><small>add image</small></div>}
                </Upload>
                <div className={`column_`}>
                    {imageList.length > 0 &&
                    <Button onClick={() => {
                        Add_Image_API()
                    }} loading={button_loading} className={`overall_button sign_in_button`}> submit upload
                    </Button>}
                </div>
            </Modal>
        </>
    )
}