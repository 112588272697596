import React from "react";
import Localforage from "localforage";
import {Get_Barber_Reserve, Delete_Reserve, Edit_Reserve} from "../apis";
import { Button, Popconfirm} from 'antd'
import Toast_noty from "../components/toasty_notification";
import {Icon} from "semantic-ui-react";
import Skeleton from "react-loading-skeleton";
import {forageToolGetData} from "../components/localForageGetData";


export default function My_reserves() {
    const [barberId, setBarber_id] = React.useState("");
    const [barberToken, setBarberToken] = React.useState("");
    const [reserves_data, setReserves_data] = React.useState([]);
    const [key_search, setKey_search] = React.useState("");
    const [skeleton_loading, setSkeleton_loading] = React.useState(false);


    async function Get_Reserve_API() {
        const token = await forageToolGetData('token')
        const id = await forageToolGetData('id')

        const Get_reserve_Rec = Get_Barber_Reserve(token,id);
        Get_reserve_Rec.then((data) => {
            if (data.result) {
                setReserves_data(data.data.reverse())
            } else {
                Toast_noty("no reserve", 5000, "warning");
            }
        })
    }

    React.useEffect(async () => {
        const token = await forageToolGetData('token')
        const barber_id = await forageToolGetData('id')
        setBarberToken(token)
        setBarber_id(barber_id)
        Get_Reserve_API();
    }, [])

    function onSearch_product(val) {
        if (val.trim() !== "") {
            setKey_search(val)
        } else {
            setKey_search("")
        }
    }

    function filter_operator(val) {
        if (key_search === "") {
            return val
        } else {
            if (val.fullname.includes(key_search)) {
                return val
            }
            if (val.reserveDate) {
                if (JSON.stringify(val.reserveDate)
                    .includes(key_search)) {
                    return val
                }
            }
            if (val.userMobile) {
                if (JSON.stringify(val.userMobile).includes(key_search)) {
                    return val
                }
            }
            if (val.reserveTime) {
                if (JSON.stringify(val.reserveTime).includes(key_search)) {
                    return val
                }
            }
        }
    }


    async function Edit_Reserve_API(id,status,type) {
        // if(type === 1){return  Toast_noty("active this item in next update", 5000, "info");}
        setSkeleton_loading(true);
        const body1 = JSON.stringify({isComplete : status})
        const body2 = JSON.stringify({isPayment : status})
        const Edit_Reserve_Rec = Edit_Reserve(id, type === 0 ? body1 : body2, barberToken, barberId);
        Edit_Reserve_Rec.then((data) => {
            setSkeleton_loading(false);
            if (data.result) {
                Get_Reserve_API()
                Toast_noty("item update successfully", 5000, "success");
            } else {
                Toast_noty("an error accrued", 5000, "error");
            }
        })
    }

    async function DELETE_Reserve_API(id) {
        setSkeleton_loading(true);
        const DELETE_Reserve_Rec = Delete_Reserve(id, barberToken, barberId);
        DELETE_Reserve_Rec.then((data) => {
            setSkeleton_loading(false);
            if (data.result) {
                Get_Reserve_API()
                Toast_noty("item removed successfully", 5000, "success");
            } else {
                Toast_noty("an error accrued", 5000, "error");
            }
        })
    }


    return (
        <>
            <div style={{paddingTop: "0"}} className={`c_f_p_c_col`}>
                <p style={{color:'#2d2c2c'}} className={`pay_notice`}>for best access insert <strong>name</strong>، <strong>phone number</strong> user، <strong>date</strong> or <strong>reserve time</strong>in search input؛</p>
                <p style={{color:'#2d2c2c'}} className={`pay_notice`}>Notice! for active the de-actived time search that reserved and remove it. </p>
                <h3>Filter by search </h3>
                <div className={`container_filter_product_with_category`}>
                    <input onChange={(val) => {
                        onSearch_product(val.target.value)
                    }} className={`head_search_input product_search_input`}
                           placeholder={"search items"}/>
                </div>
            </div>
            <div className={`all_reserve_container`}>
                {reserves_data.length !== 0 ? reserves_data.filter((val) => filter_operator(val)).map((data) =>
                    <>
                        <div className={`all_reserve_items_container`}>
                            {data.isComplete ?
                                <div className={'check_icon_complete'}>complete</div>
                                :
                                <div className={'check_icon_not_complete'}>not-complete</div>
                            }
                            {data.isPayment ?
                                <div className={'check_payment_true'}>payed (online)</div> :
                                <div className={'check_payment_false'}>not payed</div>
                            }
                            <p>user : {data.fullname}</p>
                            <p>phone number : {data.userMobile}</p>
                            <p>price : {data.price} $</p>
                            <p>date : {data.reserveDate}</p>
                            <p>income time : {data.reserveTime}</p>
                            <p>barber name : {data.barberId?.fullName}</p>
                            <div className={"row_container"}>
                                {JSON.parse(data.typeService).map(value =>  <p className={'tag_services'}>{value}</p>)}
                            </div>
                            <div className={`product_options_todo`}>
                                <Popconfirm
                                    title={'sure?'}
                                    onConfirm={() => {Edit_Reserve_API(data._id, !data.isComplete,0)}} okText="yes" cancelText="no"
                                >
                                    <Button loading={skeleton_loading}  className={`confirm_butt`}>{data.isComplete ? 'Task completed' : 'in progress'}</Button>
                                </Popconfirm>
                            </div>
                            <div className={`product_options_todo`}>
                                <Popconfirm
                                    title={'sure?'}
                                    onConfirm={() => {Edit_Reserve_API(data._id, !data.isPayment,1)}} okText="yes" cancelText="no"
                                >
                                    <Button loading={skeleton_loading} className={`confirm_butt`}>{data.isPayment ? 'payment completed' : 'payment not completed'}</Button>
                                </Popconfirm>
                            </div>
                            <div className={`product_options_todo`}>
                                <Popconfirm
                                    title={'sure?'}
                                    onConfirm={() => {DELETE_Reserve_API(data._id)}} okText="yes" cancelText="no"
                                >
                                    <Button loading={skeleton_loading} className={`confirm_butt confirm_butt_delete`}>remove <Icon name='trash'/></Button>
                                </Popconfirm>
                            </div>
                        </div>
                    </>
                ) :  <Skeleton style={{borderRadius: "25px", width: "250"}} height={350}/>}
            </div>
        </>
    )
}