import React, { useState } from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import {
    Shop_Name_Desc_Edit ,
    Get_Barbers_Admin
} from "../apis";
import {Button, Form, Modal, Tooltip, Upload, Input} from "antd";
import Localforage from "localforage";
import {UploadOutlined} from "@ant-design/icons";
import Toast_noty from "../components/toasty_notification";
import { Icon } from "semantic-ui-react";


export default function Rules() {
    const [button_loading, setButton_loading] = React.useState(false);
    const [form] = Form.useForm();


   function onFinish(val) {
    setButton_loading(true);
    const Policy_text_Edit = Shop_Name_Desc_Edit(val, true);
    Policy_text_Edit.then((data) => {
        setButton_loading(false);
        if (data.result) {
            Toast_noty("shop website details is updated", 5000, "success");
        } else {
            Toast_noty(data.mes, 5000, "error");
        }
    })
}


async function Get_Shop_API() {
    const local_data = await Localforage.getItem('data', async function (err, value) {
        return value
    });
    const Login_Rec = Get_Barbers_Admin(local_data.shop.subdomain);
    Login_Rec.then((data) => {
        // console.log(data.data.shop.rules);
        form.setFieldsValue(data.data.shop)

    })
}


React.useEffect(async () => {
    Get_Shop_API();
}, []);

    


    return (
        <>
            <div className={"shop_profile"}>
                <h1 style={{width:"100%"}}>Edit policy</h1>
                <div className={"shop_profile_add_image"}>
                </div>
                <div  className={"shop_profile_det"}>
                    <Form
                        requiredMark={false}
                        onFinish={onFinish}
                        form={form}
                        name="add_logo"
                        scrollToFirstError
                        style={{marginTop: "1rem", textAlign: "center"}}
                    >

                        <Form.List
                            name="rules"
                            rules={[
                            {
                                validator: async (_, names) => {
                                if (!names || names.length < 2) {
                                    return Promise.reject(new Error('At least 2 passengers'));
                                }
                                },
                            },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                <Form.Item
                                    // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                    label={`Policy ${index + 1}`}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                        {
                                        required: true,
                                        whitespace: true,
                                        message: "Please input policy' or delete this field.",
                                        },
                                    ]}
                                    noStyle
                                    >
                                    <Input placeholder="policy" style={{ width: '60%' }} />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                    <Icon
                                        name={'minus circle icon'}
                                        className="dynamic-delete-button"
                                        onClick={() => remove(field.name)}
                                        style={{marginLeft:"10px" , cursor:"pointer"}}
                                    />
                                    ) : null}
                                </Form.Item>
                                ))}
                                <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    htmlType="button"
                                    className={`overall_button_2`}
                                >
                                    Add policy
                                </Button>
                                </Form.Item>
                            </>
                            )}
                        </Form.List>


                        <Button loading={button_loading} className={`overall_button`} htmlType="submit">
                            Save Changes
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    )
}