import React from "react";
import {Icon} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Localforage from "localforage";
import CoachFooterSliding from "../components/coachFooterSliding";


export default function Dashboard() {
    const [is_admin, setIs_admin] = React.useState(false);
    const [tutorialPopUp, setTutorialPopUp] = React.useState(false);



    async function admin_check() {
        const token = await Localforage.getItem('i_a', async function (err, value) {
            return value
        });
        token && setIs_admin(token)
    }

    React.useEffect(()=>{
        admin_check()
    },[])

    const ListTest = [
        {
            image : "/sl1.png",
            // title: "testEts",
            description:["You can create an account for those who work for you or your collaborator. See their reservations and check them out."]
        },
        {
            image : "/sl2.png",
            // title: "2343423423r32r234r234",
            description:["In ( my reserves ) section of your control panel You can find , customers detail including: name, phone, email ,type of service selected, date and time ."]
        },
        {
            image : "/sl3.png",
            // title: "000000000",
            description:["In ( my profile section) You can manage your profile page and control the type of services that you would like to provide"]
        }
    ]

    return (
        <>
            <div onClick={()=>{setTutorialPopUp(!tutorialPopUp)}} className={"tutorial_text_alarm"}>Tutorial ?</div>
            <h2>Hello, welcome to your dashboard</h2>
            <div className={`dashboard_container`}>
                {is_admin &&
                <Link to={"/base/my-shop"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <Icon name='superpowers'/>
                        <h4>Shop Profile</h4>
                    </div>
                </Link>}
                {is_admin &&
                <Link to={"/base/barbers"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <Icon name='users'/>
                        <h4>mange barbers</h4>
                    </div>
                </Link>}
                {is_admin &&
                <Link to={"/base/all-reserves"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <div className={`dashboard_items_container_badge`}>admin</div>
                        <Icon name='folder open'/>
                        <h4>all shop reserves</h4>
                    </div>
                </Link>
                }
                <Link to={"/base/my-reserves"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <Icon name='book'/>
                        <h4>my reserves</h4>
                    </div>
                </Link>

                <Link to={"/base/work-timing"}>
                    <div style={{cursor: "pointer"}}
                         className={`dashboard_items_container`}>
                        <Icon name='user'/>
                        <h4>my profile</h4>
                    </div>
                </Link>

            </div>
            <CoachFooterSliding listData={ListTest} showStatus={tutorialPopUp} setShowStatus={(val)=>{setTutorialPopUp(val)}}/>
        </>
    )
}