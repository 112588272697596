import {BrowserRouter as Router, Route} from "react-router-dom";
import Sign_in from "./views/sign_in";
import BaseLayout from "./baseLayout";
import ResponsiveBaseLayout from "./responsiveBaseLayout";
import {ToastContainer} from "react-toastify";
import React from "react";

function App() {
    return (
        <>
                <Router>
                    <Route exact path='/' component={Sign_in}/>
                    <Route exact path='/rs' component={ResponsiveBaseLayout}/>
                    <Route exact path='/login' component={Sign_in}/>
                    <Route path='/base' component={ResponsiveBaseLayout}/>
                </Router>
                <ToastContainer position="top-right"
                                hideProgressBar={false}
                                newestOnTop
                                closeOnClick
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme={'dark'}
                />
        </>
    );
}

export default App;
