import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import {
    Add_Image_Shop, Footer_Content_Edit,
    Get_Barbers_Admin,
    ImageUrl,
    Main_text_Paper_Edit,
    Shop_Name_Desc_Edit, SiteUrl
} from "../apis";
import { Button, Form, Modal, Tooltip, Upload, Input } from "antd";
import Localforage from "localforage";
import { UploadOutlined } from "@ant-design/icons";
import Toast_noty from "../components/toasty_notification";
import OWL_PAPER from "../components/olw_shop_profile_paper";
import IconAddress_tel from "../assets/icons/social/teleg.png"
import IconAddress_wts from "../assets/icons/social/wts.png"
import IconAddress_ins from "../assets/icons/social/inst.png"
import MyShopGallery from '../components/MyShopGallery';
import QrGenerator from "../components/qrGenerator";
import { HtmlToImage } from "../components/htmlToImage";
import { forageToolGetData, forageToolSetData } from "../components/localForageGetData";
import { Icon } from "semantic-ui-react";


export default function MyShop() {
    const [shop_data, setShop_data] = React.useState({});
    const [skeleton_loading, setSkeleton_loading] = React.useState(true);
    const [image_upload_modal, setImage_upload_modal] = React.useState(false);
    const [barbers, setBarbers] = React.useState([]);
    const [button_loading, setButton_loading] = React.useState(false);

    const [PolicyForm] = Form.useForm();

    const [imageList, setImageList] = React.useState([]);

    const onChangeImage = ({ fileList: newFileList }) => {
        setImageList(newFileList);
    };
    const onPreviewImage = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    const dummyRequestImage = ({ file, onSuccess }) => {
        onSuccess("ok");
    };

    async function Get_Shop_API() {
        setSkeleton_loading(true);
        const local_data = await Localforage.getItem('data', async function (err, value) {
            return value
        });
        const Login_Rec = Get_Barbers_Admin(local_data.shop.subdomain);
        Login_Rec.then((data) => {
            PolicyForm.setFieldsValue(data.data.shop)
            footer_about_edit_form.setFieldsValue(data.data.shop)
            footer_social_edit_form.setFieldsValue(data.data.shop.footerSocial)
            footer_contact_edit_form.setFieldsValue(data.data.shop.footerContact)
            main_text_edit.setFieldsValue(data.data.shop.mainText)
            shop_name_edit.setFieldsValue(data.data.shop)
            setShop_data(data.data.shop)
            setSkeleton_loading(false);
            if (data.result) {
                setBarbers(data.data.barbers);
            } else {
                return Toast_noty(data.mes, 5000, "error")
            }
        })
    }


    // React.useEffect(async () => {
    //     const op = await forageToolGetData("qrDown")
    //     if(elementRef !== null){
    //         op !== "no" && HtmlToImage(elementRef.current.children[0], `${shop_data.subdomain}-QR-Code`)
    //         await forageToolSetData("qrDown", "no")
    //     }
    // }, [shop_data.shopName]);

    React.useEffect(async () => {
        setSkeleton_loading(true);
        Get_Shop_API();
    }, []);

    function close_modal_IMG_Upload() {
        setImage_upload_modal(false);
        setImageList([]);
    }


    async function Add_Image_API() {
        if (imageList.length === 0) {
            return Toast_noty("image is required  (:", 5000, "warning");
        }
        setButton_loading(true);
        let data = new FormData();
        data.append('image', imageList[0].originFileObj);
        const Add_Image_Shop_Rec = Add_Image_Shop(data, true);
        Add_Image_Shop_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Shop_API();
                Toast_noty("Logo Updated", 5000, "success");
                close_modal_IMG_Upload();
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    function shop_name_edit_func(val) {
        setButton_loading(true);
        const Main_text_Paper_Edit_Rec = Shop_Name_Desc_Edit(val, true);
        Main_text_Paper_Edit_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Shop_API();
                Toast_noty("shop website details is updated", 5000, "success");
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    function main_text_edit_func(val) {
        setButton_loading(true);
        const Main_text_Paper_Edit_Rec = Main_text_Paper_Edit(val, true);
        Main_text_Paper_Edit_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Shop_API();
                Toast_noty("shop details is updated", 5000, "success");
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    function footer_edit_function(val) {
        // setButton_loading(true);
        const body = {
            footerAbout: val.footerAbout ? val.footerAbout : "",
            footerContact: {
                "contact_number": val.contact_number ? val.contact_number : "",
                "support_number": val.support_number ? val.support_number : "",
                "Email": val.Email ? val.Email : ""
            },
            footerSocial: {
                "telegram": val.telegram ? val.telegram : "",
                "whatsApp": val.whatsApp ? val.whatsApp : "",
                "instagram": val.instagram ? val.instagram : ""
            }
        }
        const newData = {};
        Object.entries(body)
            .filter(([, value]) => value !== null)
            .forEach(([key, value]) => (newData[key] = value));

        const Footer_Content_Edit_Rec = Footer_Content_Edit(newData, true);
        Footer_Content_Edit_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Get_Shop_API();
                Toast_noty("shop details is updated", 5000, "success");
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    function policyOnFinish(val) {
        setButton_loading(true);
        const Policy_text_Edit = Shop_Name_Desc_Edit(val, true);
        Policy_text_Edit.then((data) => {
            setButton_loading(false);
            if (data.result) {
                Toast_noty("shop website details is updated", 5000, "success");
            } else {
                Toast_noty(data.mes, 5000, "error");
            }
        })
    }

    const [shop_name_edit] = Form.useForm();
    const [main_text_edit] = Form.useForm();
    const [footer_about_edit_form] = Form.useForm();
    const [footer_contact_edit_form] = Form.useForm();
    const [footer_social_edit_form] = Form.useForm();


    const elementRef = React.useRef();
    return (
        <>
            <div className={"shop_profile"}>
                <h1>Manage BarberShop Name and description</h1>
                <div className={"shop_profile_top_section"}>
                    <div className={"shop_profile_det"}>
                        <Form
                            requiredMark={false}
                            form={shop_name_edit}
                            name="add_logo"
                            onFinish={(val) => shop_name_edit_func(val)}
                            scrollToFirstError
                            style={{ marginTop: "1rem", textAlign: "center" }}
                        >
                            <Form.Item
                                label={`Shop Name`}
                                name="shopName"
                                rules={[{ required: true, message: "insert shop name" }]}
                            >
                                <Input placeholder={"insert shop name"} />
                            </Form.Item>
                            <Form.Item
                                label={`Description`}
                                name="description"
                                rules={[
                                    () => ({
                                        validator(rule, value) {
                                            if (value.length < 400) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(`max character = 400 | character :${value.length}`);
                                        },
                                    }),
                                ]}
                            >
                                <Input.TextArea placeholder={"insert Description"} autoSize={{ minRows: 3 }} />
                            </Form.Item>
                            <Button loading={button_loading} className={`overall_button`} htmlType="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </div>
                    {shop_data.shopName ?
                        <div ref={elementRef} id={"qr_div_id"} className={"shop_profile_Qr_Box"}>
                            <QrGenerator Value={`${SiteUrl}/bs/${shop_data?.subdomain}`}
                                LogoAddress={`${shop_data?.logo ? `${ImageUrl}/shop/logo/${shop_data?.logo}` : "/shop_def_logo.png"}`} />
                            <span onClick={() => HtmlToImage(elementRef.current.children[0], `${shop_data.subdomain}-QR-Code`)}>Download QR-Code</span>
                        </div>
                        :
                        <div className={"shop_profile_Qr_Box_skelton"}>
                            <Skeleton style={{ borderRadius: "10px" }} width={200} height={200} />
                            <Skeleton style={{ borderRadius: "10px", marginTop: "1rem" }} width={170} height={50} />
                        </div>
                    }
                </div>

                {/* policy */}
                <div className={"shop_profile_top_section"}>
                    <h1 style={{ width: "100%" }}>Edit policy</h1>
                    <div className={"shop_profile_add_image"}>

                    </div>
                    <div className={"shop_profile_det"}>
                        <Form
                            requiredMark={false}
                            onFinish={policyOnFinish}
                            form={PolicyForm}
                            name="add_logo"
                            scrollToFirstError
                            style={{ marginTop: "1rem", textAlign: "center" }}
                        >
                            <Form.List
                                name="rules"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            if (!names || names.length < 2) {
                                                return Promise.reject(new Error('At least 2 passengers'));
                                            }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                // {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                label={`Policy ${index + 1}`}
                                                required={false}
                                                key={field.key}
                                            >
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={['onChange', 'onBlur']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message: "Please input policy' or delete this field.",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input placeholder="policy" style={{ width: '60%' }} />
                                                </Form.Item>
                                                {fields.length > 1 ? (
                                                    <Icon
                                                        name={'minus circle icon'}
                                                        className="dynamic-delete-button"
                                                        onClick={() => remove(field.name)}
                                                        style={{ marginLeft: "10px", cursor: "pointer" }}
                                                    />
                                                ) : null}
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                htmlType="button"
                                                className={`overall_button_2`}
                                            >
                                                Add policy
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Button loading={button_loading} className={`overall_button`} htmlType="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </div>
                </div>


                {/* Paper*/}
                <h1>Manage Your BarberShop Detail and WebView</h1>
                <div className={"main_paper"}>
                    <div className={"paper_header_responsive"}>
                        {shop_data.logo ?
                            <Tooltip title={`click to update logo`}>
                                <div style={{ cursor: "pointer", position: "relative" }}
                                    onClick={() => {
                                        setImage_upload_modal(true)
                                    }}><LazyLoadImage
                                        alt={shop_data._id.slice(-5)}
                                        effect="blur"
                                        placeholder={<Skeleton style={{ borderRadius: "25px", width: "80px" }}
                                            height={20} />}
                                        src={`${ImageUrl}/shop/logo/${shop_data.logo}`}
                                    /></div>
                            </Tooltip> :
                            <>
                                <Tooltip title={`click to add Image`}>
                                    <div className={`add_img_div`} style={{ cursor: "pointer", position: "relative" }}
                                        onClick={() => {
                                            setImage_upload_modal(true)
                                        }}>
                                        <h4>Click Add Logo</h4>
                                        <Skeleton style={{ width: "100px" }} height={40} />
                                    </div>
                                </Tooltip>
                            </>
                        }
                        <h1>{shop_data?.shopName}</h1>
                    </div>
                    <div className={"paper_header"}>
                        {shop_data.logo ?
                            <Tooltip title={`click to update logo`}>
                                <div className={`add_img_div`} style={{ cursor: "pointer", position: "relative" }}
                                    onClick={() => {
                                        setImage_upload_modal(true)
                                    }}><LazyLoadImage
                                        alt={shop_data._id.slice(-5)}
                                        effect="blur"
                                        placeholder={<Skeleton style={{ borderRadius: "25px", width: "80px" }}
                                            height={20} />}
                                        src={`${ImageUrl}/shop/logo/${shop_data.logo}`}
                                    /></div>
                            </Tooltip> :
                            <>
                                <Tooltip title={`click to add Image`}>
                                    <div className={`add_img_div`} style={{ cursor: "pointer", position: "relative" }}
                                        onClick={() => {
                                            setImage_upload_modal(true)
                                        }}>
                                        <h4>Click Add Logo</h4>
                                        <Skeleton style={{ width: "100px" }} height={40} />
                                    </div>
                                </Tooltip>
                            </>
                        }
                        <div className={`paper_header_right`}>
                            <a>
                                <p>Services</p>
                            </a>
                            <a>
                                <p>about us</p>
                            </a>
                            <a>
                                <p>contact us</p>
                            </a>
                        </div>
                    </div>
                    <div className={`paper_body`}>

                        <div className={`paper_main_text_parent`}>
                            <div className={`paper_main_text`}>
                                <div className="paper_main_text_inner">
                                    <Form
                                        requiredMark={false}
                                        form={main_text_edit}
                                        name="main_text"
                                        onFinish={(val) => main_text_edit_func(val)}
                                        scrollToFirstError
                                        style={{ position: "relative" }}
                                    >
                                        <Form.Item
                                            name="title"
                                            rules={[{ required: true, message: "insert title" }]}
                                        >
                                            <input className={`input_main_text_paper_title`}
                                                placeholder={"Insert Title"} />
                                        </Form.Item>
                                        <Form.Item
                                            name="description"
                                            rules={[{ required: true, message: "insert title" }]}
                                        >
                                            <textarea rows={8}
                                                className={`input_main_text_paper_title input_main_text_paper_description`}
                                                placeholder={`Demo Text : Professional and experienced barber team with the aim of serving the people ...`} />
                                        </Form.Item>
                                        <Button loading={button_loading}
                                            className={`overall_button button_submit_paper button_submit_paper_mainText`}
                                            htmlType="submit">
                                            Save Changes
                                        </Button>
                                    </Form>
                                    <button className={`button_public paper_top_button`}>Book a seat now</button>
                                </div>
                            </div>
                            <div className={'paper_main_gallery'}>
                                <MyShopGallery Get_Shop_API={Get_Shop_API} shop_data={shop_data} />
                            </div>
                        </div>


                        <OWL_PAPER get_load={() => {
                            Get_Shop_API()
                        }} loading={skeleton_loading} barber_list={barbers} />
                    </div>

                    <div className={`paper_footer`}>
                        <div className={`paper_footer_sections`}>
                            <h3>About Us</h3>
                            <Form
                                requiredMark={false}
                                form={footer_about_edit_form}
                                name="about"
                                onFinish={(val) => footer_edit_function(val, 1)}
                                scrollToFirstError
                                style={{ position: "relative", height: "100%" }}
                            >
                                <Form.Item
                                    name="footerAbout"
                                    rules={[
                                        () => ({
                                            validator(rule, value) {
                                                if (value.length < 400) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(`max character = 400 | character :${value.length}`);
                                            },
                                        }),
                                    ]}
                                >
                                    <textarea rows={8}
                                        className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                        placeholder={`Demo Text : lThis template is fully responsive and created with boo ,nt is within wrapper. The wrapper is containing sidebar wrapper ,topbar header and content wost of the classes are used acording to bootstrap. Here is the general structure...`} />
                                </Form.Item>
                                <Button loading={button_loading}
                                    className={`overall_button button_submit_paper  button_submit_paper_footer`}
                                    htmlType="submit">
                                    Save Changes
                                </Button>
                            </Form>
                        </div>

                        <div className={`paper_footer_sections`}>
                            <h3>Contact Us</h3>
                            <Form
                                requiredMark={false}
                                form={footer_contact_edit_form}
                                name="contact"
                                onFinish={(val) => footer_edit_function(val, 2)}
                                scrollToFirstError
                                style={{ position: "relative", height: "100%" }}
                            >
                                <div className={`row_item_footer_paper`}>
                                    <p>Contact:</p>
                                    <Form.Item
                                        name="contact_number"
                                    >

                                        <input
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert Contact number"} />
                                    </Form.Item>
                                </div>

                                <div className={`row_item_footer_paper`}>
                                    <p>Email:</p>
                                    <Form.Item
                                        name="Email"
                                    >

                                        <input type={"email"}
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert Email"} />
                                    </Form.Item>
                                </div>
                                <div className={`row_item_footer_paper`}>
                                    <p>Address:</p>
                                    <Form.Item
                                        name="support_number"
                                    >

                                        <input
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert Address"} />
                                    </Form.Item>
                                </div>
                                <Button loading={button_loading}
                                    className={`overall_button button_submit_paper  button_submit_paper_footer`}
                                    htmlType="submit">
                                    Save Changes
                                </Button>
                            </Form>
                        </div>

                        <div className={`paper_footer_sections`}>
                            <h3>Social Link</h3>
                            <Form
                                requiredMark={false}
                                form={footer_social_edit_form}
                                name="social"
                                onFinish={(val) => footer_edit_function(val, 3)}
                                scrollToFirstError
                                style={{ position: "relative", height: "100%" }}
                            >
                                <div className={`row_item_footer_paper`}>
                                    <img src={`${IconAddress_wts}`} alt={"wts"} />
                                    <Form.Item
                                        name="telegram"
                                    >
                                        <input
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert social link"} />
                                    </Form.Item>
                                </div>
                                <div className={`row_item_footer_paper`}>
                                    <img src={`${IconAddress_ins}`} alt={"inst"} />
                                    <Form.Item
                                        name="whatsApp"
                                    >
                                        <input
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert social link"} />
                                    </Form.Item>
                                </div>
                                <div className={`row_item_footer_paper`}>
                                    <img src={`${IconAddress_tel}`} alt={"teleg"} />
                                    <Form.Item
                                        name="instagram"
                                    >
                                        <input
                                            className={`input_main_text_paper_title input_main_text_paper_description input_footer_paper_social`}
                                            placeholder={"Insert social link"} />
                                    </Form.Item>
                                </div>
                                <Button loading={button_loading}
                                    className={`overall_button button_submit_paper  button_submit_paper_footer`}
                                    htmlType="submit">
                                    Save Changes
                                </Button>
                            </Form>
                        </div>
                        <div className={`footer_hor_dev_der`} />
                        <div className={`under_paper`}>
                            <h1>Chalee Barber</h1>
                            <span>chalee.barber © 2022</span>
                        </div>

                    </div>


                </div>
            </div>
            <Modal
                title="Image Upload"
                style={{ top: 20 }}
                visible={image_upload_modal}
                footer={null}
                onCancel={() => close_modal_IMG_Upload()}
            >
                <Upload listType="picture-card"
                    action=''
                    fileList={imageList}
                    onChange={onChangeImage}
                    onPreview={onPreviewImage}
                    accept=".png,.jpg,.jpeg,.webp"
                    customRequest={dummyRequestImage}>
                    {imageList.length < 1 && <div className={`column_`}><UploadOutlined /><small>add image</small></div>}
                </Upload>
                <div className={`column_`}>
                    {imageList.length > 0 &&
                        <Button onClick={() => {
                            Add_Image_API()
                        }} loading={button_loading} className={`overall_button sign_in_button`}> submit upload
                        </Button>}
                </div>
            </Modal>
        </>
    )
}