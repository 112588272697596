import React , {useEffect, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import {Icon} from "semantic-ui-react";

import {Button, Form, Modal, Tooltip, Upload, Popconfirm} from "antd";
import ImgCrop from 'antd-img-crop';
import { BaseUrl , Update_Image_Gallery , Delete_Image_Gallery } from "../apis";
import Toast_noty from "./toasty_notification";

export default function MyShopGallery(props) {
    const[fileList , setFileList]=useState([]);
    const [button_loading, setButton_loading] = React.useState(false);

    // Function For Upload Handel

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        };
    
    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
        };
    
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
        };
    
    // End Function For Upload 

    async function updateGallery() {
        if (fileList.length === 0) {
            return Toast_noty("image is required  (:", 5000, "warning");
        }
        setButton_loading(true);
        let data = new FormData();
        data.append('image', fileList[0].originFileObj);
        const Add_Image_Shop_Rec = Update_Image_Gallery(data, true);
        Add_Image_Shop_Rec.then((data) => {
            setButton_loading(false);
            if (data.result) {
                setFileList([])
                props.Get_Shop_API()
                Toast_noty("gallery Updated", 5000, "success");
            } else {
                Toast_noty("error", 5000, "error");
            }
        })
    }


    async function deleteImage(id) {
        const Main_text_Paper_Edit_Rec = Delete_Image_Gallery(id, true);
        Main_text_Paper_Edit_Rec.then((data) => {
            if (data.result) {
                props.Get_Shop_API();
                Toast_noty("Delete success", 5000, "success");
            } else {
                Toast_noty("error", 5000, "error");
            }
        })
    }


    return (
    
            <div className={'paper_main_gallery_inner'}>
            <Button onClick={updateGallery} loading={button_loading} className={`overall_button button_submit_paper button_submit_paper_mainText`}>
                Save Changes
            </Button>
            <div className={'paper_main_gallery_inner_img'}>
                <ImgCrop aspect={2/1}>
                    <Upload
                    className="mt-2"
                    accept=".png , .jpg , .jpeg , .pdf "
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                    customRequest={dummyRequest}
                    showUploadList={{showPreviewIcon:false}}
                    >
                        {fileList.length <1 && 
                        <div className="detail-product-pivot-cont-inner-left-upload">
                            <span>Add image</span>
                        </div>
                        }
                    </Upload>
                </ImgCrop>
                {props.shop_data.gallery?.map(item=>
                    <div className={'paper_main_gallery_inner_img_item'}>
                        <Popconfirm placement="topLeft" title={"Are you sure to delete this image?"} onConfirm={()=>deleteImage(item)} okText="Yes" cancelText="No">
                            <div className="paper_main_gallery_inner_img_item_trash">
                                <Icon name='trash'/>
                            </div>
                        </Popconfirm>
                        <img src={`${BaseUrl}/shop/gallery/${item}`} alt="img" />
                    </div>
                )}
                
            </div>
            </div>
    )
}