import React from 'react';
import {QRCode} from 'react-qrcode-logo';


export default function QrGenerator({Value, LogoAddress}) {
    return (
        <>
            <QRCode
                bgColor={"#ffffff"}
                size={175}
                logoWidth={40}
                logoHeight={40}
                value={Value}
                eyeRadius={[
                    {
                        outer: [10, 10, 0, 10],
                        inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0], // top/right eye
                    [10, 0, 10, 10], // bottom/left
                ]}
                // logoImage={LogoAddress}
            />
        </>
    )
}