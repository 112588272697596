import React from "react";
import {Route, useHistory} from "react-router-dom";
import {Icon} from "semantic-ui-react";
import {forageToolDeleteData, forageToolGetData, forageToolSetData} from "./components/localForageGetData";
import Dashboard from "./views/dashboard";
import Barbers from "./views/barbers";
import Profile from "./views/profile";
import All_reserves from "./views/all_reserves";
import My_reserves from "./views/my-reserves";
import MyShop from "./views/my-shop";
import Rules from "./views/rules";
import {ImageUrl, SiteUrl} from "./apis";
import FireSend from "./components/fireBase";
import {Tooltip} from "antd";
import QrGenerator from "./components/qrGenerator";
import {HtmlToImage} from "./components/htmlToImage";


export default function ResponsiveBaseLayout(props) {
    const Routing = useHistory();
    const [pageLocation, setPageLocation] = React.useState("")
    const [dropMenuPagesVis, setDropMenuPagesVis] = React.useState(false);
    const [is_admin, setIs_admin] = React.useState(false);
    const [closeFooterPopUp, setCloseFooterPopUp] = React.useState(true);
    const [showIniMessage, setShowIniMessage] = React.useState(false);
    const [name, setName] = React.useState("");
    const [data, setData] = React.useState("");

    const elementRef = React.useRef();

    async function admin_check() {
        const mainAdmin = await forageToolGetData("i_a")
        const name_forage = await forageToolGetData("name")
        const data_forage = await forageToolGetData("data")
        setName(name_forage)
        setData(data_forage)
        setIs_admin(mainAdmin)
    }

    React.useEffect(async () => {
        await admin_check()
        const showIniMessage = await forageToolGetData("showIniMessage")
        if (showIniMessage === null) {
            await forageToolSetData("showIniMessage", "show")
            setShowIniMessage(true)
        } else if (showIniMessage === "show") {
            setShowIniMessage(true)
        } else {
            setShowIniMessage(false)
        }
        FireSend();
    }, [])

    async function dontShowAgain() {
        setCloseFooterPopUp(!closeFooterPopUp)
        setTimeout(async () => {
            await forageToolSetData("showIniMessage", "doNotShow")
            setShowIniMessage(false)
        }, 600)
    }

    React.useEffect(() => {
        let PagePathname = Routing.location.pathname
        setPageLocation(PagePathname.substring(PagePathname.substring(1).indexOf("/") + 2))
    }, [Routing.location.pathname])

    React.useEffect(() => {
        setDropMenuPagesVis(false)
    }, [Routing.location.pathname])

    const pagesRoutsData = [
        {
            pathName: "/base/dashboard",
            icon: "dashboard",
            name: "dashboard"
        },
        {
            pathName: "/base/my-shop",
            icon: "superpowers",
            name: "my shop"
        },
        {
            pathName: "/base/barbers",
            icon: "users",
            name: "barbers"
        },
        {
            pathName: "/base/all-reserves",
            icon: "folder open",
            name: " all reserves"
        },
        {
            pathName: "/base/my-reserves",
            icon: "book",
            name: "my reserves"
        },
        {
            pathName: "/base/work-timing",
            icon: "user",
            name: "my profile"
        },
    ]

    async function logOut() {
        await forageToolDeleteData("token")
        await forageToolDeleteData("id")
        await forageToolDeleteData("name")
        await forageToolDeleteData("data")
        await forageToolDeleteData("i_a")
        await forageToolDeleteData("showIniMessage")
        Routing.push("/");
    }

    React.useEffect(() => {
        const dropdownElm = document.getElementById('dropdownElm');
        const inputElm = document.getElementById('inputElm');
        window.addEventListener('click', function (e) {
            if (!inputElm.contains(e.target)) {
                if (!dropdownElm.contains(e.target)) {
                    setDropMenuPagesVis(false);
                }
            }

        });
    }, [])

    return (
        <div>
            <div className={`container-tabs`}>
                <div style={{position: "relative"}} className={`div-row`}>
                    <span style={{marginRight: "1rem", fontSize: "18px"}}>Page</span>
                    <input id={"inputElm"} onChange={(e) => {
                        setPageLocation(e.target.value)
                    }}
                           onClick={() => {
                               setDropMenuPagesVis(!dropMenuPagesVis)
                           }}
                           value={pageLocation}
                           type={"text"} className={`select-page-input`}/>
                    <div id={"dropdownElm"}
                         className={`container-rout-items ${dropMenuPagesVis && "container-rout-items-vis"}`}>
                        {pagesRoutsData.map(val =>
                            <div className={`rout-items`} onClick={() => Routing.push(val.pathName)}>
                                <Icon name={val.icon}/> <span>{val.name}</span>
                            </div>
                        )}
                        <div className={`rout-items rout-items-log-out`} onClick={() => logOut()}>
                            <Icon name={"sign out"}/> <span>Logout</span>
                        </div>

                    </div>
                </div>
                <div className={`head-name-container`}>
                    {is_admin ?
                        <h3>{name} (Admin)</h3>
                        :
                        <h3>{name}</h3>
                    }
                </div>
                <Tooltip placement="bottom" title={"Your Website"}>
                    <a className={`go-to-shop`} style={{color: "#fff"}} href={`${SiteUrl}/bs/${data?.shop?.subdomain}`}
                       target="_blank">
                        <Icon name={"tv"}/>
                    </a>
                </Tooltip>

            </div>

            <div className={`children-container`}>

                {props.children}
                <Route path='/base/dashboard' component={Dashboard}/>
                <Route path='/base/barbers' component={Barbers}/>
                <Route path='/base/work-timing' component={Profile}/>
                <Route path='/base/all-reserves' component={All_reserves}/>
                <Route path='/base/my-reserves' component={My_reserves}/>
                <Route path='/base/my-shop' component={MyShop}/>
                <Route path='/base/rules' component={Rules}/>
            </div>
            {showIniMessage &&
                <div
                    className={`ini_po_up_shop_container ${!closeFooterPopUp && "ini_po_up_shop_container_de_active"}`}>
                    <div className={"ini_po_up_shop_container_relative"}>
                        <div onClick={() => {
                            setCloseFooterPopUp(!closeFooterPopUp)
                        }} className={"ini_po_up_shop_close_button"}>close
                        </div>
                        <div onClick={() => {
                            dontShowAgain()
                        }}
                             className={"ini_po_up_shop_close_button ini_po_up_shop_dont_show_again_button"}>Don't Show
                            Again
                        </div>

                        <div className={"ini_po_up_shop_text_box"}>
                            <h2> Welcome to the barber site management panel</h2>
                            <p>The barcode you see is made especially for you and your customers can enter your
                                dedicated website by scanning this barcode and make a reservation, and you can also see
                                the reservation of customers.</p>
                        </div>
                        <div ref={elementRef} className={"ini_po_up_shop_QR_code"}>
                            <QrGenerator Value={`${SiteUrl}/bs/${data?.shop?.subdomain}`}
                                         LogoAddress={`${data?.shop?.logo ? `${ImageUrl}/shop/logo/${data?.shop?.logo}` : "/shop_def_logo.png"}`}/>
                            {/*<h3>Your BarberShop QR-Code</h3>*/}
                            <span
                                onClick={() => HtmlToImage(elementRef.current.children[0], `${data?.shop?.subdomain}-QR-Code`)}>Download QR-Code</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}