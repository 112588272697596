import React from "react";
import {toPng} from 'html-to-image';


export function HtmlToImage(Element, D_link) {
    if (Element === null) alert("not have an element ti image")
    const dom = Element
    const domedEl = toPng(dom, {quality: 1,cacheBust: true,preferredFontFormat:"opentype"})
    domedEl.then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `${D_link ? D_link : "barbersite-QR-CODE"}.jpeg`;
        link.href = dataUrl;
        link.click();
    })
        .catch((err) => {
            console.log(err)
        })

}