import Localforage from "localforage";

export async function get_token (){
    return await Localforage.getItem('token', async function (err, value) {
        return value
    })
}

export const BaseUrl = "https://api.barberssite.com";
export const ImageUrl = "https://api.barberssite.com";
export const SiteUrl = "https://barberssite.com";

// export const BaseUrl = "http://192.168.1.101:9898";
// export const ImageUrl = "http://192.168.1.101:9898";

// export const BaseUrl = "http://localhost:9898";




async function Fetch(url, method, bodyReq, token = false) {
    if (method === "GET" || method === "HEAD") {
        const Response = await fetch(`${BaseUrl}${url}`, {
            mode: 'cors',
            method: method,
            headers: token  ?
                {'token': `${await get_token()}`, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
                :
                {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        });
        return Response.json();
    } else {
        const Response = await fetch(`${BaseUrl}${url}`, {
            mode: 'cors',
            method: method,
            body: bodyReq,
            headers: token ?
                {'token': `${await get_token()}`, 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'}
                :
                {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'},
        });
        return Response.json();
    }
}

async function Fetch_Form(url, method, bodyReq, token ) {
    const Response = await fetch(`${BaseUrl}${url}`, {
        mode: 'cors',
        method: method,
        body: bodyReq,
        headers: {'token': `${await get_token()}`, 'Access-Control-Allow-Origin': '*'}
    });
    return Response.json();
}


export const Get_single_Barbers = (id, token) => {
    return Fetch(`/barber/specified/${id}`, "GET", "", token)
}

export const Get_Barbers_Admin = (name) => {
    return Fetch(`/barber/shop/admin/${name}`, "GET", "", "")
}

export const Sign_In = (val) => {
    const post_data = {
        email: val.email,
        password: val.password
    }
    return Fetch("/barber/login", "POST", JSON.stringify(post_data), "")
}


export const Add_New_Barbers = (body, token) => {
    return Fetch("/barber", "POST", JSON.stringify(body), token)
}


export const Add_Image_Barbers = (body, barber, token) => {
    return Fetch_Form(`/barber/profile/${barber}`, "PUT", body, token)
}


export const Add_Image_Shop = (body, token) => {
    return Fetch_Form(`/shop/logo`, "PUT", body, token)
}

export const Update_Image_Gallery = (body, token) => {
    return Fetch_Form(`/shop/gallery`, "PUT", body, token)
}


export const Delete_Image_Gallery = (id, token) => {
    return Fetch_Form(`/shop/delete_image/${id}`, "DELETE", "", token)
}


export const Remove_Barbers = (id, token) => {
    return Fetch(`/barber/${id}`, "DELETE", "", token)
}

export const Get_Barber_List_Time = (id) => {
    return Fetch(`/reserve/listReserve/barber/${id}`, "GET", "", "")
}


export const Edit_Profile = (barberId, data, token) => {
    return Fetch(`/barber/edit/${barberId}`, "PUT", JSON.stringify(data), token)
}


export const Get_all_reserve_for_admin = (admin_token) => {
    return Fetch(`/reserve/shop`, "GET", '', admin_token)
}


export const Get_Barber_Reserve = (barber_token, barber) => {
    return Fetch(`/reserve/barber/${barber}`, "GET", '', barber_token)
}


export const Edit_Reserve = (id, body, token, barber_id) => {
    return Fetch(`/reserve/barber/${barber_id}/reserve/${id}/`, "PUT", body, token)
}


export const Delete_Reserve = (id, token, barber_id) => {
    return Fetch(`/reserve/barber/${barber_id}/reserve/${id}`, 'DELETE', '', token)
}


export const Post_New_Reserve = (body) => {
    return Fetch(`/reserve`, "POST", body, "")
}


export const Block_Times = (body, id, token) => {
    return Fetch(`/reserve/timeBlock/barber/${id}`, "POST", body, token)
}


export const Barber_active_de_active = (id, body, token) => {
    return Fetch(`/barber/status/${id}`, "PUT", JSON.stringify(body), token)
}


export const Shop_Name_Desc_Edit = (body, token) => {
    return Fetch(`/shop/edit`, "PUT", JSON.stringify(body), token)
}


export const Main_text_Paper_Edit = (body, token) => {
    return Fetch(`/shop/edit/main-text`, "PUT", JSON.stringify(body), token)
}


export const Footer_Content_Edit = (body, token) => {
    return Fetch(`/shop/edit/footer`, "PUT", JSON.stringify(body), token)
}


export const Fire_Base = (body, token) => {
    return Fetch(`/barber/fire`, "PUT", JSON.stringify(body), token)
}