import React from "react";
import Skeleton from "react-loading-skeleton";
import {ImageUrl} from "../apis";
import {LazyLoadImage} from "react-lazy-load-image-component";


export default function CoachFooterSliding({listData, showStatus = false, setShowStatus}){
    const [listSlider, setListSlider] = React.useState([]);
    const [itemToShow, setItemToShow] = React.useState({});

    const [pageNum, setPageNum] = React.useState(0)


    React.useEffect(()=>{
        setListSlider(listData)
        setItemToShow(listData[0])
    },[])

    React.useMemo(()=>{
        setItemToShow(listSlider[pageNum])
    },[pageNum])

    function nextPage(){
        pageNum < listData.length - 1 && setPageNum(pageNum + 1)
    }

    function PrevPage(){
        pageNum > 0 && setPageNum(pageNum - 1)
    }


    return(
        <div className={`coach_footer_slider_main_container ${!showStatus && "coach_footer_slider_main_container_de_active"}`}>
            <div className={`coach_footer_slider_main_container_relative`}>
                <div className={`coach_footer_slider_main_content_page_num`}>
                    page {`${listSlider.length}/${pageNum + 1}`}
                </div>
                <div onClick={()=>{setShowStatus(!showStatus)}} className={`coach_footer_slider_top_button coach_footer_slider_top_button_closing`}>close</div>
                {listSlider.length > 0 ?
                    <div key={pageNum} className={`coach_footer_slider_main_content_container`}>
                        <div className={`coach_footer_slider_main_content_container_details`}>
                            <h2>{itemToShow.title}</h2>
                            {itemToShow.description.map(val => <p>{val}</p>)}
                        </div>
                        <div className={`coach_footer_slider_main_content_container_img`}>
                            <LazyLoadImage alt={`${pageNum}-slide`} effect="blur"
                                           placeholder={<Skeleton style={{borderRadius: "25px"}} width={600} height={600}/>}
                                           src={itemToShow.image}/>
                        </div>
                    </div>
                    :
                    "waiting"
                }
            </div>
            <div className={`coach_footer_slider_main_next_prev_buttons_container`}>
                <div style={{background:`${pageNum <= 0 ? "#bbbbbb" : "#2f54eb"}`}}
                     onClick={()=>{PrevPage()}} className={`coach_footer_slider_main_N_P_button`}>previous</div>
                <div style={{background:`${ pageNum === listData.length - 1 ? "#bbbbbb" : "#2f54eb"}`}}
                     onClick={()=>{nextPage()}} className={`coach_footer_slider_main_N_P_button`}>next</div>
            </div>

        </div>
    )
}