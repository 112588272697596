import Localforage from "localforage";

export async function forageToolGetData(itemsName) {
    return await Localforage.getItem(itemsName, async function (err, value) {
        return value
    })
}

export async function forageToolSetData(itemsName, value) {
    return await Localforage.setItem(itemsName, value)
}


export async function forageToolDeleteData(itemsName) {
    return await Localforage.removeItem(itemsName)
}

